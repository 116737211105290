import { DASHBOARD_PERMISSIONS } from '@app/modules/dashboard/models/dashboard.const';
import { ACCOUNTS_PERMISSIONS } from '@app/modules/accounts/models/accounts.const';
import { POSITIONS_PERMISSIONS } from '@app/modules/positions/models/positions.const';
import { CANDIDATES_PERMISSIONS } from '@app/modules/candidates/models/candidates.const';
import { REQUISITIONS_PERMISSIONS } from '@app/modules/requisitions/models/requisitions.const';
import { INTERVIEWS_PERMISSIONS } from '@app/modules/interviews/models/interviews.const';
import { TIME_SHEET_PERMISSIONS } from '@app/modules/finance/time-sheets/models/time-sheets.const';
import { KANDA_TIME_SHEET_PERMISSIONS } from '@app/modules/finance/kanda-time-sheets/models/kanda-time-sheets.const';
import { BILLINGS_PERMISSIONS } from '@app/modules/finance/billing/models/billings.const';
import { INVOICES_PERMISSIONS } from '@app/modules/finance/invoices/models/invoices.const';
import { RATE_CARDS_PERMISSIONS } from '@app/modules/finance/rate-cards/models/rate-cards.const';
import { ROLES_PERMISSIONS } from '@app/modules/admin/models/roles.const';
import { HISTORY_LOGS_PERMISSIONS } from '@app/shared/components/history-logs/models/history-logs.const';
import { PARTNER_BILL_PERMISSIONS } from '@app/modules/finance/accounting-pages/models/partners-bills.const';
import { USER_PERMISSIONS } from '@app/modules/admin/models/users.const';
import { EXPENSES_PERMISSIONS } from '@app/modules/kanda-ca/expenses/models/expenses.const';
import { KEY_PERFORMANCE_INDICATORS_PERMISSIONS } from '@app/modules/kanda-ca/key-performance-indicators/models/key-performance-indicators.const';

export const DASHBOARD_PAGE_PERMISSIONS = [
  DASHBOARD_PERMISSIONS.readCurrentActivities,
  DASHBOARD_PERMISSIONS.readActiveStaffings,
  DASHBOARD_PERMISSIONS.readUtilizations,
  DASHBOARD_PERMISSIONS.readOnboardingPerformances,
];

export const STAFFING_BOARD_PAGE_PERMISSIONS = [
  ACCOUNTS_PERMISSIONS.readByFilter,
  POSITIONS_PERMISSIONS.readByFilter,
  CANDIDATES_PERMISSIONS.readByFilter,
  REQUISITIONS_PERMISSIONS.readByFilter,
  CANDIDATES_PERMISSIONS.readCandidatesWithColorByFilter,
  POSITIONS_PERMISSIONS.readById,
];

export const ONBOARDING_PAGE_PERMISSIONS = [
  STAFFING_BOARD_PAGE_PERMISSIONS,
  CANDIDATES_PERMISSIONS.readByFilter,
  REQUISITIONS_PERMISSIONS.readByFilter,
  POSITIONS_PERMISSIONS.readByFilter,
  INTERVIEWS_PERMISSIONS.readByFilter,
];

export const FINANCE_OTHER_PAGE_PERMISSIONS = [
  TIME_SHEET_PERMISSIONS.readByFilter,
  KANDA_TIME_SHEET_PERMISSIONS.readByFilter,
  TIME_SHEET_PERMISSIONS.getTimeSheetsAllByFilter,
  BILLINGS_PERMISSIONS.readByFilter,
];

export const FINANCE_ACCOUNTING_PAGES_PERMISSIONS = [
  PARTNER_BILL_PERMISSIONS.readByFilter,
];

export const FINANCE_PERMISSIONS = [
  RATE_CARDS_PERMISSIONS.read,
  BILLINGS_PERMISSIONS.readGlobalBilling,
  FINANCE_OTHER_PAGE_PERMISSIONS,
  INVOICES_PERMISSIONS.readByFilter,
];

export const GLOBAL_BILLING_PAGE_PERMISSIONS = [
  BILLINGS_PERMISSIONS.readGlobalBilling,
  BILLINGS_PERMISSIONS.getHeader,
];

export const INVOICE_PAGE_PERMISSIONS = [
  INVOICES_PERMISSIONS.readByFilter,
  INVOICES_PERMISSIONS.readTotal,
];

export const SETTINGS_PAGE_PERMISSIONS = [
  USER_PERMISSIONS.read,
  ROLES_PERMISSIONS.read,
  HISTORY_LOGS_PERMISSIONS.readByFilter,
];

export const KANDA_CA_PERMISSIONS = [
  EXPENSES_PERMISSIONS.readByFilter,
  KEY_PERFORMANCE_INDICATORS_PERMISSIONS.read,
];
