import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ACCOUNTS_PERMISSIONS } from '@app/modules/accounts/models/accounts.const';
import { CANDIDATES_PERMISSIONS } from '@app/modules/candidates/models/candidates.const';
import { EMPLOYEES_PERMISSIONS } from '@app/modules/employees/models/employees.const';
import { INTERVIEWS_PERMISSIONS } from '@app/modules/interviews/models/interviews.const';
import { POSITIONS_PERMISSIONS } from '@app/modules/positions/models/positions.const';
import { PROJECTS_PERMISSIONS } from '@app/modules/projects/models/projects.const';
import { REQUISITIONS_PERMISSIONS } from '@app/modules/requisitions/models/requisitions.const';
import { VENDORS_PERMISSIONS } from '@app/modules/vendors/models/vendors.const';
import {
  ACCOUNTS,
  ADMIN,
  CANDIDATES,
  DASHBOARD,
  EMPLOYEES,
  FINANCE,
  INTERVIEWS,
  KANDA_CA,
  ONBOARDING,
  PERSONAL_PROFILE,
  PERSONAL_TIME_SHEETS,
  PLACEMENTS,
  POSITIONS,
  PROJECTS,
  RATE_CARDS,
  REPORTS,
  REQUISITIONS,
  VENDORS,
} from '@app/shared/const';
import {
  DASHBOARD_PAGE_PERMISSIONS,
  FINANCE_PERMISSIONS,
  KANDA_CA_PERMISSIONS,
  ONBOARDING_PAGE_PERMISSIONS,
  SETTINGS_PAGE_PERMISSIONS,
  STAFFING_BOARD_PAGE_PERMISSIONS,
} from '@app/shared/const/page-permissions.const';
import { CLICKABLE_LINKS } from '@common/components/link/link.component';
import { CLICKABLE_ROWS } from '@common/components/table/table.component';
import AuthLayoutComponent from '@common/components/wrapper/layout/auth-layout/auth-layout.component';
import ContentLayoutComponent from '@common/components/wrapper/layout/content-layout/content-layout.component';
import SimpleWrapperComponent from '@common/components/wrapper/simple-wrapper/simple-wrapper.component';
import { ROUTES, SETTINGS } from '@common/const';
import { PermissionsMode } from '@common/directives/permission-if.directive';
import { RoleEnum } from '@common/generated/graphql';
import {
  authGuard,
  doesNotHaveSingleRoleGuard,
  hasRoleGuard,
  hasSimpleWrapperGuard,
  protectedGuard,
  publicGuard,
} from '@common/guards';
import { AuthorizationService } from '@common/services/authorization.service';
import FallbackRouteService from '@common/services/fallback-route.service';
import { NOT_FOUND_ROUTE, UNAUTHORIZED_ROUTE } from '@common/shared.routing';
import { TranslateService } from '@ngx-translate/core';

import { KANDA_TIME_SHEET_PERMISSIONS } from './modules/finance/kanda-time-sheets/models/kanda-time-sheets.const';
import { PLACEMENTS_PERMISSIONS } from './modules/placement/models/placements.const';
import { REPORTS_PERMISSIONS } from './modules/reports/models/reports.const';

const INTERVIEWS_ROUTES = {
  path: INTERVIEWS.root,
  loadChildren: () => import('./modules/interviews/interviews.routes'),
  data: {
    breadcrumb: 'INTERVIEWS',
    permission: INTERVIEWS_PERMISSIONS.readByFilter,
  },
};

const routes: Routes = [
  {
    path: ROUTES.auth,
    component: AuthLayoutComponent,
    canMatch: [publicGuard],
    children: [
      {
        path: '',
        data: {
          isGoogleSignInEnabled: true,
        },
        loadChildren: () => import('@common/modules/auth/auth.module'),
      },
    ],
  },
  {
    path: ROUTES.root,
    component: SimpleWrapperComponent,
    data: { breadcrumb: 'gTOP' },
    canMatch: [hasSimpleWrapperGuard],
    canActivate: [authGuard],
    children: [
      NOT_FOUND_ROUTE,
      UNAUTHORIZED_ROUTE,
      {
        path: '',
        pathMatch: 'full',
        canMatch: [
          () =>
            inject(AuthorizationService).hasSingleRole(RoleEnum.Interviewer),
        ],
        redirectTo: INTERVIEWS.root,
      },
      {
        canMatch: [hasRoleGuard(RoleEnum.Interviewer)],
        ...INTERVIEWS_ROUTES,
      },
    ],
  },
  {
    path: ROUTES.root,
    component: ContentLayoutComponent,
    data: { breadcrumb: 'gTOP' },
    canActivate: [authGuard, doesNotHaveSingleRoleGuard(RoleEnum.Interviewer)],
    children: [
      NOT_FOUND_ROUTE,
      UNAUTHORIZED_ROUTE,
      {
        path: ROUTES.root,
        pathMatch: 'full',
        canMatch: [
          () => inject(AuthorizationService).hasRole(RoleEnum.Resource),
        ],
        children: [
          {
            path: ROUTES.root,
            pathMatch: 'full',
            redirectTo: '/' + PERSONAL_PROFILE.root,
          },
        ],
      },
      {
        path: ROUTES.root,
        pathMatch: 'full',
        redirectTo: DASHBOARD.root,
      },
      {
        path: SETTINGS.root,
        loadChildren: () =>
          import('./modules/settings/gtop-user-settings.routes'),
      },
      {
        path: PERSONAL_PROFILE.root,
        canMatch: [
          protectedGuard,
          () => inject(AuthorizationService).hasRole(RoleEnum.Resource),
        ],
        loadChildren: () =>
          import('./modules/personal-profile/personal-profile.routes'),
        data: {
          breadcrumb: 'PERSONAL_PROFILE',
          permission: EMPLOYEES_PERMISSIONS.readPersonalProfile,
          fallback: PERSONAL_TIME_SHEETS.root,
        },
        providers: [
          {
            provide: CLICKABLE_LINKS,
            useValue: false,
          },
        ],
      },
      {
        ...INTERVIEWS_ROUTES,
        canMatch: [
          protectedGuard,
          () => inject(AuthorizationService).isInterviewerOrEmployee,
        ],
        providers: [
          {
            provide: CLICKABLE_ROWS,
            useValue: true,
          },
          {
            provide: CLICKABLE_LINKS,
            useValue: false,
          },
        ],
      },
      {
        path: PERSONAL_TIME_SHEETS.root,
        loadComponent: () =>
          import(
            './modules/personal-time-sheets/personal-time-sheets.component'
          ),
        data: {
          breadcrumb: 'TIME_SHEETS',
          permission: KANDA_TIME_SHEET_PERMISSIONS.readPersonalTimeSheets,
          fallback: DASHBOARD.root,
        },
        providers: [
          {
            provide: CLICKABLE_LINKS,
            useValue: false,
          },
        ],
        canMatch: [protectedGuard],
      },
      {
        path: '',
        canActivate: [
          () => !inject(AuthorizationService).isInterviewerOrEmployee,
        ],
        canLoad: [() => !inject(AuthorizationService).isInterviewerOrEmployee],
        canMatch: [() => !inject(AuthorizationService).isInterviewerOrEmployee],
        children: [
          NOT_FOUND_ROUTE,
          UNAUTHORIZED_ROUTE,
          {
            path: DASHBOARD.root,
            data: {
              breadcrumb: 'DASHBOARD',
              permission: DASHBOARD_PAGE_PERMISSIONS,
              permissionsMode: PermissionsMode.Every,
              fallback: ACCOUNTS.root,
            },
            loadComponent: () =>
              import('./modules/dashboard/dashboard.component'),
            canMatch: [protectedGuard],
          },
          {
            path: ACCOUNTS.root,
            loadChildren: () => import('./modules/accounts/accounts.routes'),
            data: {
              breadcrumb: 'ACCOUNTS',
              permission: ACCOUNTS_PERMISSIONS.readByFilter,
              fallback: PROJECTS.root,
            },
            canMatch: [protectedGuard],
          },
          {
            path: PROJECTS.root,
            loadChildren: () => import('./modules/projects/projects.routes'),
            data: {
              breadcrumb: 'PROJECTS',
              permission: PROJECTS_PERMISSIONS.readByFilter,
              fallback: PLACEMENTS.root,
            },
            canMatch: [protectedGuard],
          },
          {
            path: PLACEMENTS.root,
            loadChildren: () => import('./modules/placement/placements.routes'),
            data: {
              breadcrumb: 'PLACEMENTS',
              permission: PLACEMENTS_PERMISSIONS.readByFilter,
              fallback: VENDORS.root,
            },
            canMatch: [protectedGuard],
          },
          {
            path: VENDORS.root,
            loadChildren: () => import('./modules/vendors/vendors.routes'),
            data: {
              breadcrumb: 'VENDORS',
              permission: VENDORS_PERMISSIONS.readByFilter,
              fallback: EMPLOYEES.root,
            },
            canMatch: [protectedGuard],
          },
          {
            path: EMPLOYEES.root,
            loadChildren: () => import('./modules/employees/employees.routes'),
            data: {
              breadcrumb: 'EMPLOYEES',
              permission: EMPLOYEES_PERMISSIONS.readByFilter,
              fallback: ONBOARDING.root,
            },
            canMatch: [protectedGuard],
          },
          {
            path: ONBOARDING.root,
            data: {
              breadcrumb: 'ONBOARDING',
              permission: ONBOARDING_PAGE_PERMISSIONS,
              fallback: REPORTS.root,
            },
            children: [
              {
                path: '',
                pathMatch: 'full',
                redirectTo: ONBOARDING.staffingBoard,
              },
              {
                path: ONBOARDING.staffingBoard,
                loadChildren: () =>
                  import('./modules/staffing-board/staffing-board.routes'),
                data: {
                  breadcrumb: 'STAFFING_BOARD',
                  permission: STAFFING_BOARD_PAGE_PERMISSIONS,
                  permissionsMode: PermissionsMode.Every,
                  fallback: [ONBOARDING.root, REQUISITIONS.root],
                },
                canMatch: [protectedGuard],
              },
              {
                path: REQUISITIONS.root,
                loadChildren: () =>
                  import('./modules/requisitions/requisitions.routs'),
                data: {
                  breadcrumb: 'REQUISITIONS',
                  permission: REQUISITIONS_PERMISSIONS.readByFilter,
                  fallback: [ONBOARDING.root, CANDIDATES.root],
                },
                canMatch: [protectedGuard],
              },
              {
                path: CANDIDATES.root,
                loadChildren: () =>
                  import('./modules/candidates/candidates.routes'),
                data: {
                  breadcrumb: 'CANDIDATES',
                  permission: CANDIDATES_PERMISSIONS.readByFilter,
                  fallback: [ONBOARDING.root, POSITIONS.root],
                },
                canMatch: [protectedGuard],
              },
              {
                path: POSITIONS.root,
                loadChildren: () =>
                  import('./modules/positions/positions.routes'),
                data: {
                  breadcrumb: 'POSITIONS',
                  permission: POSITIONS_PERMISSIONS.readByFilter,
                  fallback: REPORTS.root,
                },
                canMatch: [protectedGuard],
              },
              {
                canMatch: [
                  () =>
                    inject(AuthorizationService).hasRole(RoleEnum.Interviewer),
                ],
                ...INTERVIEWS_ROUTES,
              },
            ],
            canMatch: [protectedGuard],
          },
          {
            path: REPORTS.root,
            loadChildren: () => import('./modules/reports/reports.routes'),
            data: {
              breadcrumb: 'REPORTS',
              permission: REPORTS_PERMISSIONS.read,
              fallback: [FINANCE.root, RATE_CARDS.root],
            },
            canMatch: [protectedGuard],
          },
          {
            path: ADMIN.root,
            data: {
              breadcrumb: 'SETTINGS',
              permission: SETTINGS_PAGE_PERMISSIONS,
            },
            loadChildren: () => import('./modules/admin/admin.routes'),
            canMatch: [protectedGuard],
          },
          {
            path: FINANCE.root,
            data: {
              breadcrumb: { alias: 'FINANCE' },
              permission: FINANCE_PERMISSIONS,
            },
            loadChildren: () => import('./modules/finance/finance.routes'),
            canMatch: [protectedGuard],
          },
          {
            path: KANDA_CA.root,
            data: {
              breadcrumb: { alias: 'KANDA_CA' },
              permission: KANDA_CA_PERMISSIONS,
            },
            loadChildren: () => import('./modules/kanda-ca/kanda-ca.routes'),
            canMatch: [protectedGuard],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
  providers: [TranslateService],
})
export class AppRoutingModule {
  // This service is initialized here for routing fallbacks resolving.
  // Resolving is implemented in protectedGuard
  fallbackRouterService = inject(FallbackRouteService);
}
