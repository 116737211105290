export const PARTNER_BILL_PERMISSIONS = {
  readByFilter: {
    module: 'PartnerBill',
    action: 'GetPartnerBillsByFilter',
  },
  readTotal: {
    module: 'PartnerBill',
    action: 'GetPartnerBillTotal',
  },
  updateDetails: {
    module: 'PartnerBill',
    action: 'UpdatePartnerBillDetails',
  },
  updatePartnerBillComment: {
    module: 'PartnerBill',
    action: 'UpdatePartnerBillComment',
  },
  updatePartnerBillAdditionalCost: {
    module: 'PartnerBill',
    action: 'UpdatePartnerBillAdditionalCost',
  },
  updateBillStatus: {
    module: 'PartnerBill',
    action: 'UpdatePartnerBillStatus',
  },
  readById: {
    module: 'PartnerBill',
    action: 'GetPartnerBillById',
  },
  updateIndependentBillInvoice: {
    module: 'PartnerBill',
    action: 'UpdateIndependentBillInvoice',
  },
  updateIndependentBillIssueDate: {
    module: 'PartnerBill',
    action: 'UpdateIndependentBillIssueDate',
  },
  getPartnerBillInvoiceById: {
    module: 'PartnerBill',
    action: 'GetPartnerBillInvoiceById',
  },
  clearPartnerBillDetails: {
    module: 'PartnerBill',
    action: 'ClearPartnerBillDetails',
  },
};
